import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Popup } from 'semantic-ui-react';
import { GPSLocation } from '../../../backend_api/models';
import Button from '../../../base/components/basic/Button';
import EditContextMenuButton from '../../../base/components/basic/EditLink';
import RemoveIcon from '../../../base/components/basic/RemoveIcon';
import { preventDefaultAndStopPropagation } from '../../../base/utils';
import { TextItem } from '../../globals/components';
import QInput from '../../inspections/components/edit/formelements/QInput';
import ContentSection from '../../pageLayouts/components/ContentSection';

type Props = {
    allowedToEdit: boolean;
    address: string[];
    contactPerson: string;
    handleChange(name: string, value: string | GPSLocation, index?: number): void;
    onAddAddressLine(): void;
    onRemoveAddressLine(index: number): void;
};


const DetailedProductionUnitAddressAndContactPerson = (props: Props): React.ReactElement => {
    const { address, contactPerson, handleChange, allowedToEdit } = props;

    const intl = useIntl();
    const [edit, setEdit] = useState<boolean>(false);
    return <ContentSection
        content={<>
            {!edit && <div className='space-y-3'>
                {<TextItem label={intl.formatMessage({ id: 'production_units.detailed_page.info.contact_person' })} text={props.contactPerson || '-'} />}
                {<TextItem label={intl.formatMessage({ id: 'production_units.detailed_page.info.address' })} text={props.address.toString() || '-'} />}
            </div>}

            {edit && (
                <>
                    <div className='flex pb-4'>
                        <div className='w-1/4'><FormattedMessage id='production_units.detailed_page.info.contact_person' /></div>
                        <div className='w-2/4'>
                            <QInput
                                type='text'
                                name='contact_person'
                                value={contactPerson}
                                handleBlur={(e, item) => {
                                    handleChange(item.name, item.value)
                                }} />
                        </div>
                    </div>

                    <div className='flex pb-4'>
                        <div className='w-1/4'><FormattedMessage id='production_units.detailed_page.info.address' /></div>
                        <div className='w-3/4'>
                            {address.length === 0 && <span className='pr-2'>No address.</span>}
                            {address.map((addressField, i) => {
                                return <div className='items-center flex w-full space-x-2' key={'addr_ ' + i}>
                                    <div className='w-full mb-1'><QInput
                                        className='w-11/12 flex'
                                        key={'address_' + i}
                                        type='text'
                                        name='name'
                                        value={addressField}
                                        handleBlur={(e, item) => {
                                            handleChange('address', item.value, i)
                                        }} />
                                    </div>
                                    <Popup trigger={<span><Button onClick={(e) => {
                                        props.onRemoveAddressLine(i);
                                        preventDefaultAndStopPropagation(e);
                                    }} className='px-2 py-1 text-xs'><RemoveIcon iconClassName='text-sm' /></Button></span>}>
                                        <FormattedMessage id='production_units.detailed_page.info.click_to_remove_address_line' />
                                    </Popup>
                                </div>
                            })}
                            <span className='link text-sm' onClick={props.onAddAddressLine}>Add</span>
                        </div>
                    </div>
                </>
            )}

            {allowedToEdit && <EditContextMenuButton className='flex w-full justify-end' onEdit={setEdit} />}
        </>}
    />
}
export default DetailedProductionUnitAddressAndContactPerson;
