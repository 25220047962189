import React, { useState } from 'react';
import { twMerge } from '../../utils';
import ContextMenu from '../../ui/components/contextMenu/ContextMenu';

type Props = {
    className?: string;
    edit?: boolean;
    onEdit(isEditable: boolean): void;
};

const EditContextMenuButton = (props: Props): React.ReactElement => {
    const { className, onEdit } = props;
    const [edit, setEdit] = useState<boolean>(props.edit || false);

    const contextMenu = <ContextMenu
        className={twMerge('h-fit-content', className)}
        actions={edit ? [
            {
                label: 'globals.done',
                onPress: () => {
                    onEdit(!edit);
                    setEdit(!edit);
                },
                labelRight: true
            }
        ] : [
            {
                label: 'globals.edit',
                icon: 'operation_edit',
                labelLeft: true,
                onPress: () => {
                    onEdit(!edit);
                    setEdit(!edit);
                }
            }
        ]}
    />;

    return contextMenu;
}
export default EditContextMenuButton;
