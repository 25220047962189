import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import EditContextMenuButton from '../../../base/components/basic/EditLink';
import Group from '../../groups/components/Group';
import { ControlledImmediateGroupsSelector } from '../../groups/components/GroupsSelector';
import { useGroups } from '../../groups/hooks';
import ContentSection from '../../pageLayouts/components/ContentSection';
import { getGroupsById } from '../../users/selectors';

type Props = {
    className?: string;
    groupIds?: string[];
    productionUnitId: string;
    onGroupAdded(groupId: string): void
    onGroupRemoved(groupId: string): void
    allowedToEdit: boolean
};

const DetailedProductionUnitGroups = (props: Props): React.ReactElement => {
    const { groupIds } = props;
    const intl = useIntl();
    const [edit, setEdit] = useState<boolean>(false);
    const groupsById = useSelector(getGroupsById)

    const selectableGroups = useGroups().filter(g => !g.is_supplier_group);

    return <ContentSection
        headerText={<div className='flex justify-between items-center w-full'>{intl.formatMessage({ id: 'production_units.detailed_page.groups.heading' })}
            {props.allowedToEdit && <EditContextMenuButton onEdit={setEdit} />}
        </div>}
        content={<div>
            {!edit && groupIds.length === 0 && <div><FormattedMessage id='production_units.detailed_page.no_groups_added' /></div>}
            {edit && <p><FormattedMessage id='production_units.detailed_page.groups.add_or_remove' /></p>}

            {edit && <ControlledImmediateGroupsSelector
                onGroupAdded={props.onGroupAdded}
                onGroupRemoved={props.onGroupRemoved}
                className=''
                selectableGroups={selectableGroups}
                selectedGroupIds={props.groupIds}
            />}

            {!edit && <div className='pb-4'>
                {groupIds
                    .map((groupId) => {
                        const group = groupsById[groupId];

                        if (group) {
                            return <Group key={'group_' + groupId} group={groupsById[groupId]} />
                        }
                        return null
                    })
                    .filter(g => g != null)}
            </div>}
        </div>}
    />;
}
export default DetailedProductionUnitGroups;
